import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import FilterModal from './FilterModal';
import Store from "./Store";
import search from '../../assets/search.png';
import search1 from '../../assets/search1.png';
import filter from '../../assets/filter.png';
import oops from '../../assets/oops.png';
import Spinner from '../Spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import LoginBusinessStoreModal from "./LoginBusinessStoreModal";
import * as bg from '../../assets/bgColors/index';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class StoresScreen extends React.Component {
    constructor(props) {
        super(props)
        this.scrollUpRef = React.createRef();
        this.state = {
             modalIsOpen: false,
             loginBusStoreIsOpen: false,
             chosenShop: null,
             isMobile: window.innerWidth <= 800
        }
    }

    openLoginBusStoreModal = (shop) => {
        this.setState({chosenShop: shop, loginBusStoreIsOpen: true})
    }

    closeLoginBusStoreModal = () => {
        this.setState({loginBusStoreIsOpen: false})
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 800 });
    }

    componentDidUpdate() {
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
    }
    
    componentDidMount = async () => {
        const { getShopsResp, setShopId, setShopName} = this.props.myShopApiStore;

        // if(!shopsData){
            setShopId(null);
            setShopName(null);
            await getShopsResp();
        // }
        this.scrollUpRef.current && this.scrollUpRef.current.scrollIntoView({behavior: 'smooth'})
        window.addEventListener('resize', this.updateIsMobile)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    renderShop = (shop, idx) => {
        return(
            <div key={idx}>
                <Store shop={shop} openLoginBusStoreModal={this.openLoginBusStoreModal}/>
            </div>
        )
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

 

    render() {
        const { shopsData, filteredShops, cancleFiltereds, hasFilters, shopId } = this.props.myShopApiStore;

        if(!shopsData && shopId === 1){
            return null;
        }
        const isFiltered = filteredShops.length || !hasFilters; /* >>>> added !hasFilters  (for not found shops) !!!!! */
        return (
            
            (()=>{

                const cMap = {
                    '#00B1E1': bg.bgBlue, // 1	כחול	00B1E1
                    '#1BB449': bg.bgGreen, // 2	ירוק	1BB449
                    '#EB0028': bg.bgRed, // 3	אדום	EB0028
                    '#F1A103': bg.bgYellow, // 4	כתום	F1A103
                    '#292929': bg.bgBlack // 5	אפור	292929
                }
                let root = document.querySelector(':root');
                let color = process.env.REACT_APP_START_COLOR;
                if (shopsData?.startColor.length) {
                    color = '#'+shopsData.startColor;
                    root.style.setProperty('--bg-image', `url(${cMap[color]}) no-repeat #FFFFFF`);
                }
                root.style.setProperty('--start-color', color);
            })(),
            <div className={style.Container} ref={this.scrollUpRef}>
                <LoginBusinessStoreModal 
                    modalIsOpen={this.state.loginBusStoreIsOpen}
                    closeModal={this.closeLoginBusStoreModal}
                    shop={this.state.chosenShop}
                    />
                <div className={style.TopMain}>
                    <div className={style.TopRightBtns}>
                        <div className={style.FilterBtn} onClick={this.openModal} >
                            <LazyLoadImage effect='blur' src={this.state.modalIsOpen || this.state.isMobile ? search : search1} width={this.state.isMobile ? '18px' : 'unset'}/>
                        </div>
                        <div className={isFiltered ? style.CancleFilterBtn : style.DisabledCancleFilterBtn} onClick={cancleFiltereds}>
                            {
                                isFiltered 
                                ?
                                    <>
                                        <LazyLoadImage effect='blur' src={filter}/>
                                        <span>בטל סינון</span>
                                    </>
                                :
                                    null
                            }
                        </div>
                    </div>
                    <div className={style.Title}>
                        {shopsData ? shopsData.welcomeMessage : ''}
                    </div>
                    <div className={style.Spinner}>
                        <Spinner loading={!shopsData} color="#c3f5ff" loader="BeatLoader"/>
                    </div>
                </div>
                <div className={style.ScreenBody}>
                    {
                        shopsData
                        ?
                            filteredShops.length
                            ?
                                <div className={style.StoreList}>
                                    
                                    { filteredShops.map(this.renderShop) }
                                    
                                </div>
                            :
                                !hasFilters
                                ?   
                                    <div className={style.Oops}>
                                        <LazyLoadImage effect='blur' src={oops} width="200px"/>
                                        <h3>לא נמצאו תוצאות...</h3>
                                    </div>
                                :
                                    <div className={style.StoreList}>
                                        { shopsData.shops.map(this.renderShop) }
                                    </div>
                        :
                            ''
                    }
                </div>
                <FilterModal modalIsOpen={this.state.modalIsOpen} closeModal={this.closeModal} />
            </div>
          
            
        )
    }
}

export default StoresScreen
